import React from "react";


import "./dashBoardStyle.css";
import { Link, NavLink,  } from "react-router-dom";


import {

  BsHddNetworkFill,

} from "react-icons/bs";
import {
  RiDashboardLine,
 
} from "react-icons/ri";

function Sidebar() {
  return (
    <>
      <aside className="leftPanel">
        <div className="logo pt-2 pb-3">
          <Link to="/dashboard-page">
            <img className="lgLogo" src="../images/logo-full.png" />
          </Link>
          <span>
            <img className="iconLogo" src="../images/logoIcon.png" />
          </span>
        </div>
        <div className="taskMenu">
          <ul>
            <li>
              <NavLink to="/dashboard-page">
                <RiDashboardLine />
                <span>Dashboard</span>
              </NavLink>
            </li>
            <li>
              <a href="#">
                <BsHddNetworkFill />
                <span>Integrations</span>
              </a>
            </li>
          
          
          </ul>
        </div>
      </aside>
    </>
  );
}

export default Sidebar;
