import { createSlice } from "@reduxjs/toolkit";

export const sliceEmail = createSlice({
  name: "emaildata",
  initialState: {
    data: {},
    selectemail: {},
    status: {},
    mailstatus: {},
    selectedmesage: {},
    uploadedFile: null, // New state for storing the uploaded file
    columnNames: [],
    fileName: "",
    sequencetime: "",
  },
  reducers: {
    fetched: (state, { payload }) => {
      state.data = payload.data;
    },
    recevierdata: (state, { payload }) => {
      state.data = payload.data;
    },
    selectedEmail: (state, { payload }) => {
      state.selectemail = payload;
    },
    status: (state, { payload }) => {
      state.status = payload;
    },
    selectedmesage: (state, { payload }) => {
      state.selectedmesage = payload;
    },
    mailstatus: (state, { payload }) => {
      state.mailstatus = payload;
    },
    setUploadedFile: (state, { payload }) => {
      state.uploadedFile = payload; // Reducer to update uploaded file
    },
    setColumnNames: (state, { payload }) => {
      state.columnNames = payload; // Reducer to update column names
    },
    setFileName: (state, { payload }) => {
      state.fileName = payload;
    },
    setsequence: (state, { payload }) => {
      state.sequencetime = payload;
    },
  },
});

export const {
  fetched,
  recevierdata,
  selectedEmail,
  status,
  selectedmesage,
  mailstatus,
  setUploadedFile, // New action for setting uploaded file
  setColumnNames,
  setFileName,
  setsequence,
} = sliceEmail.actions;

export const SetSenderData = (data) => async (dispatch) => {
  // if (!data) throw Error("missing Data parameter");
  dispatch(fetched({ data }));
};

export const SetRecevierData = (data) => async (dispatch) => {
  // if (!data) throw Error("missing Data parameter");
  dispatch(recevierdata({ data }));
};

export const setSelectedEmail = (data) => async (dispatch) => {
  dispatch(selectedEmail({ data }));
};

export const setStatus = (data) => async (dispatch) => {
  dispatch(status({ data }));
};

export const setmailStatus = (data) => async (dispatch) => {
  dispatch(mailstatus({ data }));
};

export const setselectedmesage = (data) => async (dispatch) => {
  dispatch(selectedmesage({ data }));
};

export const uploadFile = (file) => async (dispatch) => {
  console.log("file", file);
  dispatch(setUploadedFile(file));
};

export const storeColumnNames = (columnNames) => async (dispatch) => {
  dispatch(setColumnNames(columnNames)); // Dispatch the extracted column names
};
export const importFileName = (fileName) => async (dispatch) => {
  dispatch(setFileName(fileName)); // Dispatch the extracted column names
};
export const setSequenceTime = (time) => async (dispatch) => {
  dispatch(setsequence(time)); // Dispatch the extracted column names
};

export default sliceEmail.reducer;
