export const BASECONFIG = {
  BASE_URL: process.env.REACT_APP_API_BACKEND_URL,
};
export const BASECONFIGTEXTMODAL = {
  BASE_URL: process.env.REACT_APP_API_AI_TEXT_BACKEND_URL,
};
export const BASECONFIGIMAGEMODAL = {
  BASE_URL: process.env.REACT_APP_API_AI_BACKEND_URL,
};

export const BASECONFIGVOIP = {
  BASE_URL: process.env.REACT_APP_VOIP_BACKEND_URL,
};
