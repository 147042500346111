import React, { useEffect, useState } from "react";

import "./dashBoardStyle.css";
import Sidebar from "./Sidebar";
import Dropdown from "react-bootstrap/Dropdown";
import { Link } from "react-router-dom";
import { IoPencilOutline } from "react-icons/io5";
import { Box } from "@mui/material";
import Modal from "../PopupModal/Modal";
import PopupModal from "../PopupModal/Modal";
import {
  MaterialReactTable,
  getMRT_RowSelectionHandler,
  useMaterialReactTable,
} from "material-react-table";
import { API } from "../../apiwrapper";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { SetPopupReducerData } from "../../store/reducer/PopupReducer";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

// import { useDispatch, useSelector } from "react-redux";
// import { SetUserData } from "../Store/reducer/authUser";

function Automation() {
  //   const userData = useSelector((state) => state?.authUser?.data);

  const dispatch = useDispatch();
  const { PopupReducer } = useSelector((state) => state);
  const MySwal = withReactContent(Swal);

  const { showModal } = PopupReducer?.modal || {};
  const initialColumns = [
    { accessorKey: "name", header: "Name" },
    { accessorKey: "description", header: "Description" },
    { accessorKey: "service", header: "Service" },
    { accessorKey: "service_cost", header: "Service Cost" },
    { accessorKey: "total_cost", header: "Total Cost" },
  ];
  const [record, setrecord] = useState([]);
  const [Data, setData] = useState([]);
  const [columns, setColumns] = useState(initialColumns);
  const [visibleColumns, setVisibleColumns] = useState(() =>
    initialColumns.reduce((acc, column) => {
      acc[column.accessorKey] = true;
      return acc;
    }, {})
  );
  const [columnOrder, setColumnOrder] = useState([
    "mrt-row-actions",
    "mrt-row-select",
    ...initialColumns.map((col) => col.accessorKey),
  ]);

  useEffect(() => {
    ListData();
  }, [showModal]);

  const ListData = async () => {
    try {
      const response = await API({
        url: `/api/integrations`,
        method: "GET",
      });
      console.log("Raw response:", response);

      if (response) {
        console.log(response, "35464545");
        const sanitizedData =
          response &&
          response?.data?.length > 0 &&
          response?.data?.map((ele) => {
            return {
              name: ele?.name,
              description: ele?.description,
              service: ele?.service,
              service_cost: ele?.margin_cost,
              total_cost: ele?.total_cost,
              Id: ele?._id,
            };
          });

        setData(sanitizedData);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const table = useMaterialReactTable({
    columns: columns.filter((col) => visibleColumns[col.accessorKey]),
    data: Data,
    enableColumnOrdering: true,
    enableColumnResizing: true,
    columnResizeMode: "onChange",
    initialState: { columnOrder },
    createDisplayMode: "modal",
    editDisplayMode: "modal",
    enableFullScreenToggle: false,
    enableDensityToggle: false,
    // enableEditing: true,
    enableRowSelection: true,
    renderTopToolbarCustomActions: ({ table }) => (
      <Box sx={{ display: "flex", gap: "1rem", p: "4px" }}>
        {table.getSelectedRowModel().rows && (
          <>
            <Button
              color="secondary"
              disabled={
                !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
              }
              onClick={() => {
                // dispatch(
                //   SetPopupReducerData({
                //     modalType: "CREATGROUP",
                //     showModal: true,
                //     contact: [],
                //   })
                // );
                const selectedRows = table.getSelectedRowModel().rows;
                console.log("selectedRows", selectedRows);

                if (selectedRows && selectedRows.length > 0) {
                  setrecord((prevContacts) => {
                    let contactId = "";

                    selectedRows.forEach((ele) => {
                      contactId = ele.original?.Id;
                      console.log("contactId", contactId);
                    });

                    dispatch(
                      SetPopupReducerData({
                        modalType: "INIGRATION",
                        showModal: true,
                        Id: contactId,
                      })
                    );
                  });
                }
              }}
              variant="outline-dark"
            >
              Edit
            </Button>
            <Button
              color="secondary"
              disabled={
                !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
              }
              onClick={(e) => {
                const selectedRows = table.getSelectedRowModel().rows;
                console.log("selectedRows", selectedRows);

                if (selectedRows && selectedRows.length > 0) {
                  setrecord((prevContacts) => {
                    let contactId = "";

                    selectedRows.forEach((ele) => {
                      contactId = ele.original?.Id;
                      console.log("contactId", contactId);
                    });

                    confirmDelete(contactId);
                  });
                }
              }}
              variant="outline-dark"
            >
              Delete
            </Button>
          </>
        )}
      </Box>
    ),
  });

  const confirmDelete = (id) => {
    console.log("Confirm delete triggered for id:", id); // Debugging line
    MySwal.fire({
      title: "Are you sure?",
      text: "Do you really want to delete the record? .",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        handleDelete(id);
      }
    });
  };

  const handleDelete = async (recordId) => {
    console.log("Deleting record with ID:", recordId); // Debugging line
    try {
      const data = await API({
        url: `/api/integrations/${recordId}`,
        method: "DELETE",
      });
      if (data && data?.status === true) {
        console.log("Delete success:", data);
        ListData(); // Refresh the list after deletion
      } else {
        console.log("Delete failed:", data);
      }
    } catch (error) {
      console.error("Delete error:", error);
    }
  };

  return (
    <>
      <Sidebar />

      <section className="dashBoxMain " style={{ marginLeft: "50px" }}>
        <div className="pageTitle1 p-5">
          <h3>Integrations </h3>
          <p>{Data?.length} Available</p>
        </div>
        <button></button>
        <button
          variant="contained"
          className="transParent py-2 px-4 ms-auto"
          onClick={() =>
            dispatch(
              SetPopupReducerData({
                modalType: "INIGRATION",
                showModal: true,
              })
            )
          }
          style={{
            backgroundColor: "#7233a3",
            color: "white",
          }}
        >
          Add Integration
        </button>
        <div className="mt-3">
          <MaterialReactTable table={table} columns={columns} />
        </div>
      </section>
    </>
  );
}

export default Automation;
