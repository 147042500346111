import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { IoCloseOutline } from "react-icons/io5";
import { API } from "../../apiwrapper";
import { useDispatch, useSelector } from "react-redux";
import { SetPopupReducerData } from "../../store/reducer";

const PopupModal = () => {
  const { PopupReducer } = useSelector((state) => state);
  const dispatch = useDispatch();
  const userData = useSelector((state) => state?.authUser);
  const { showModal, Id } = PopupReducer?.modal || {};
  console.log("Id", Id);
  const handleClosePopup = () => {
    dispatch(
      SetPopupReducerData({
        modalType: "",
        showModal: false,
        buyModal: false,
      })
    );
  };
  const [inputfield, setInputfield] = useState({
    name: "",
    description: "",
    service: "",
    margin_cost: 0,
    total_cost: 0,
  });

  useEffect(() => {
    if (Id) {
      detailRecord();
    }
  }, [Id]);

  const detailRecord = async () => {
    try {
      const detail = await API({
        url: `/api/integrations/${Id}`,
        method: "GET",
      });
      if (detail && detail?.status === true) {
        setInputfield({
          name: detail?.data?.name,
          description: detail?.data?.description,
          service: detail?.data?.service,
          margin_cost: detail?.data?.margin_cost,
          total_cost: detail?.data?.total_cost,
        });
      }
    } catch (error) {}
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputfield((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const payload = {
      name: inputfield.name,
      description: inputfield.description,
      service: inputfield.service,
      margin_cost: inputfield.margin_cost,
      total_cost: inputfield.total_cost,
    };

    // Log the payload (You can also send this to your API)
    console.log("Payload:", payload);
    if (Id) {
      try {
        const response = await API({
          url: `/api/integrations/${Id}`,
          method: "PUT",
          body: payload,
        });
        console.log("Raw response:", response);

        if (response) {
          console.log("response", response);
          handleClosePopup();
        }
      } catch {}
    } else {
      try {
        const response = await API({
          url: `/api/integrations`,
          method: "POST",
          body: payload,
        });
        console.log("Raw response:", response);

        if (response) {
          console.log("response", response);
          handleClosePopup();
        }
      } catch {}
    }

    // You can handle closing the modal here if needed
  };

  return (
    <Modal
      show={showModal}
      onHide={handleClosePopup}
      size="lg"
      centered
      backdrop="static"
      keyboard={false}
      style={{ backdropFilter: "blur(10px)" }}
      className="taskModal"
    >
      <Modal.Header className="login_header">
        <h3 style={{ fontSize: "24px" }}>Create </h3>
        <button className="btnClose ms-auto" onClick={handleClosePopup}>
          <IoCloseOutline />
        </button>
      </Modal.Header>
      <Modal.Body className="px-5">
        <form className="row" onSubmit={handleSubmit}>
          <div className="col-6">
            <div className="inputData my-4">
              <input
                type="text"
                name="name"
                id="name"
                className="my-2"
                value={inputfield.name}
                onChange={handleChange}
              />
              <label htmlFor="name">Name</label>
            </div>
          </div>

          <div className="col-6">
            <div className="inputData my-4">
              <input
                type="text"
                name="description"
                id="description"
                className="my-2"
                value={inputfield.description}
                onChange={handleChange}
              />
              <label htmlFor="description">Description</label>
            </div>
          </div>

          <div className="col-6">
            <div className="inputData my-4">
              <input
                type="text"
                name="service"
                id="service"
                className="my-2"
                value={inputfield.service}
                onChange={handleChange}
              />
              <label htmlFor="service">Service</label>
            </div>
          </div>

          <div className="col-6">
            <div className="inputData my-4">
              <input
                type="text"
                name="margin_cost"
                id="margin_cost"
                className="my-2"
                value={inputfield.margin_cost}
                onChange={handleChange}
              />
              <label htmlFor="margin_cost">Service Cost</label>
            </div>
          </div>

          <div className="col-6">
            <div className="inputData my-4">
              <input
                type="text"
                name="total_cost"
                id="total_cost"
                className="my-2"
                value={inputfield.total_cost}
                onChange={handleChange}
              />
              <label htmlFor="total_cost">Total Cost</label>
            </div>
          </div>

          <div className="col-12">
            <div className="d-flex gap-4 mt-5 mb-4 justify-content-end">
              <Button className="btnNobg py-2" onClick={handleClosePopup}>
                Cancel
              </Button>
              <Button className="btnSubmit py-2" type="submit">
                Create
              </Button>
            </div>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default PopupModal;
