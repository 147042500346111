import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Automation from "./component/dashboard/Automation";
import { useEffect, useState } from "react";
import OpenModal from "./component/PopupModal/openModal";
import { useSelector } from "react-redux";

function App() {
  const [showPopup, setShowPopup] = useState(false);
  const PopupReducer = useSelector((state) => state.PopupReducer);
  const { showModal } = PopupReducer?.modal || {};

  useEffect(() => {
    setShowPopup(!!showModal);
    return () => {
      setShowPopup(false);
    };
  }, [showModal]);
  return (
    <>
      {showPopup && <OpenModal />}

      <Routes>
        <Route index element={<Automation />} />
      </Routes>
    </>
  );
}

export default App;
