import React from "react";
import { useSelector } from "react-redux";
import PopupModal from "./Modal";

function OpenModal() {
  const { PopupReducer } = useSelector((state) => state);
  const { modalType = "", showModal } = PopupReducer?.modal || {};

  return (
    <>{showModal && <>{modalType === "INIGRATION" && <PopupModal />}</>}</>
  );
}

export default OpenModal;
